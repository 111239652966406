import React from 'react'
import AddAffiliateLinks from './AddAffiliateLinks';

function AddAffiliateLinksWrapper() {
  return (
    <>
    <div className='page-body px-4'>
        <AddAffiliateLinks/>
    </div>
    </>
  )
}

export default AddAffiliateLinksWrapper;