import React from 'react'
import AdminAddAffiliateLinks from './AddAffiliateLinks.jsx';

function AdminAddAffiliateLinksWrapper() {
  return (
    <>
    <div className='page-body px-4'>
        <AdminAddAffiliateLinks/>
    </div>
    </>
  )
}

export default AdminAddAffiliateLinksWrapper;